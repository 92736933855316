import env from "@beam-australia/react-env"
import { CreateSubscriptionPayload, Tenant, TenantInvitations, TenantReservation, TenantRules, User } from "@contextualio/contextual-silo-auth"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CreateIcon from "@mui/icons-material/Create"
import ErrorIcon from "@mui/icons-material/Error"
import { Alert, Box, Button, Card, CardActions, CardContent, IconButton, Link, List, ListItem, Stack, TextField, Typography } from "@mui/material"
import axios, { AxiosError } from "axios"
import Lottie from "lottie-react"
import { ChangeEvent, FormEvent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react"
import { z } from "zod"

import SendAnimation from "../../assets/animations/send.json"
import { useAlert } from "../../context/alert"
import { useCustomer } from "../../context/customer"
import { useTenants } from "../../context/tenant"
import { useSettings } from "../../context/theme"
import { useUser } from "../../context/user"
import { useTenantsService, useUsersService } from "../../providers"
import { getSubscriptionPayload } from "../../utils/customers"
import { handleRedirect } from "../../utils/navigation"
import { findErrorByName, getErrorMessage, getFormErrors } from "../../utils/validate-form"
import { CustomButton } from "../Button"

const domain = env("MY_DOMAIN")

type SuccessIconProps = {
  finished?: boolean
}

const SuccessIcon = ({ finished = false }: SuccessIconProps) =>
  <CheckCircleIcon color={finished ? "success" : "disabled"} />

type CreateTenantSuccessProps = {
  email: User["email"]
  tenantId?: string
}

const CreateTenantSuccess = ({ email, tenantId }: CreateTenantSuccessProps) => {
  const { mode } = useSettings()
  const { setAlert } = useAlert()
  const { getTenant } = useTenantsService()
  const { getLastTenantInvitation, resendInvitation } = useUsersService()

  const { tenantsInProgress, fetchTenantsWithState } = useTenants()

  const [tenantInvitation, setTenantInvitation] = useState<TenantInvitations>()
  const [provisionedTenant, setProvisionedTenant] = useState<Tenant>()

  const [step, setStep] = useState(0)

  useEffect(() => {
    if (!tenantsInProgress || tenantsInProgress?.length === 0) {
      fetchTenantsWithState()
    }
  }, [fetchTenantsWithState, tenantsInProgress])

  useEffect(() => {
    if (step < 3) {
      const intervalId = setInterval(() => setStep(step => step + 1), 2000)
      return () => clearTimeout(intervalId)
    }
  }, [step])

  const handleSSLPolling = useCallback(async (tenantId: string) => {
    const tenant = await getTenant(tenantId)

    if (tenant.processed && tenant.state === "provisioned" && tenant.sslCertificate === "ready") {
      setProvisionedTenant(tenant)
    }
  }, [getTenant])

  const handleResendInvitation = useCallback(async () => {
    try {
      await resendInvitation()
      setAlert({
        message: "Invitation sended successfully",
        severity: "success",
      })
    } catch (error) {
      setAlert({
        message: "Error creating user account",
        severity: "error",
      })
    }
  }, [resendInvitation, setAlert])

  useEffect(() => {
    if (tenantId && !provisionedTenant) {
      const intervalId = setInterval(() => handleSSLPolling(tenantId), 15000)
      return () => clearTimeout(intervalId)
    }
  }, [handleSSLPolling, provisionedTenant, step, tenantId])

  useEffect(() => {
    if (tenantId && !!provisionedTenant) {
      const get = async () => {
        const rsp = await getLastTenantInvitation(tenantId)
        setTenantInvitation(rsp)
      }
      get()
    }
  }, [getLastTenantInvitation, provisionedTenant, tenantId])

  const Footer = useCallback(() => {
    if (tenantInvitation && provisionedTenant) {
      return (
        <Button
          fullWidth
          onClick={() => handleRedirect({ ...provisionedTenant, baseDomain: `${provisionedTenant.baseDomain}/invitations?id=${tenantInvitation.inviteToken}&mode=${mode}` })}
        >
          Go to my new Tenant!
        </Button>
      )
    }

    return (
      <Stack mt={3} textAlign={"left"}>
        {provisionedTenant && tenantInvitation && <Stack direction={"row"} spacing={2}>
          <Typography variant="body1">
            <b>Haven’t received it?</b>
          </Typography>
          <Link onClick={handleResendInvitation}>Resend</Link>
        </Stack>}
        <Typography>
          Your tenant welcome email will be from <b>onboarding@contextual.io</b>. Check your junk or spam folder, and add us to your safe sender list.
        </Typography>
      </Stack>
    )
  }, [handleResendInvitation, mode, provisionedTenant, tenantInvitation])

  return (
    <>
      <Stack spacing={1} alignContent={"center"} alignItems={"center"} >
        <Typography variant="h4">
          We're on it!
        </Typography>
        <Lottie
          style={{
            height: 250,
            width: 450,
          }}
          animationData={SendAnimation} />
      </Stack>
      <Stack spacing={3} justifyContent={"space-between"} textAlign={"left"}>
        <Stack>
          <Typography variant="body1">
            <b>Keep an eye on your email!</b>
          </Typography>
          <Typography variant="body1">
            We’ll send an email to <b>{email}</b> when your tenant is ready to use.
          </Typography>
        </Stack>
        <Stack spacing={5} direction={"row"} justifyItems={"center"} justifyContent={"space-between"}>
          <Typography variant="body1">
            Tenant URL Provisioned
          </Typography>
          <SuccessIcon finished={step >= 1} />
        </Stack>
        <Stack spacing={5} direction={"row"} justifyItems={"center"} justifyContent={"space-between"}>
          <Typography variant="body1">
            Customer and User Accounts Linked
          </Typography>
          <SuccessIcon finished={step >= 2} />
        </Stack>
        <Stack spacing={5} direction={"row"} justifyItems={"center"} justifyContent={"space-between"}>
          <Typography variant="body1">
            Tenant SSL Active
          </Typography>
          <SuccessIcon finished={!!provisionedTenant && !!tenantInvitation} />
        </Stack>
      </Stack>
      <Footer />
    </>
  )
}

type Props = {
  label?: string
  success?: boolean
  reservations?: TenantReservation[]
  setReservations: (data?: TenantReservation[]) => void
  onSubmit?: (data: CreateSubscriptionPayload) => Promise<void>
  withCardWrapper?: boolean
}

export const CreateTenant = ({ label = "Your first tenant", onSubmit, withCardWrapper = false, success = false, reservations, setReservations }: Props) => {
  const { user } = useUser()
  const { customer } = useCustomer()
  const { createTenantReservation, deleteTenantReservations, getTenantRules } = useTenantsService()
  const { setAlert } = useAlert()
  const { tenantsInProgress } = useTenants()

  const [loading, setLoading] = useState(false)

  const [rules, setRules] = useState<TenantRules>()

  const subscriptionData = useMemo(() => customer ? getSubscriptionPayload(customer) : undefined, [customer])

  const initialData = useMemo<CreateSubscriptionPayload | undefined>(() => {
    if (!reservations || reservations.length === 0) return

    const { name, displayName, reservationId } = reservations[reservations.length - 1]

    return {
      tenantDisplayName: displayName ?? name,
      tenantName: name,
      tenantReservationId: reservationId,
      ...subscriptionData!,
    }
  }, [reservations, subscriptionData])

  const [data, setData] = useState<CreateSubscriptionPayload | undefined>(initialData)
  const [reservationError, setReservationError] = useState<AxiosError>()

  useEffect(() => {
    const get = async () => {
      const rsp = await getTenantRules()
      setRules(rsp)
    }
    get()
  }, [getTenantRules])

  const CreateSubscriptionPayloadWithValidation = useMemo(() => {
    if (!rules) return CreateSubscriptionPayload

    return CreateSubscriptionPayload.superRefine((data, ctx) => {
      if (rules.name.notEnum.includes(data.tenantName)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Tenant name ${data.tenantName} is not allowed`,
          fatal: true,
          path: ["tenantName"],
        })
      }

      if (data.tenantName.length < rules.name.minLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Tenant url is to short",
          fatal: true,
          path: ["tenantName"],
        })
      }

      if (data.tenantName.length > rules.name.maxLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Tenant name is to long",
          fatal: true,
          path: ["tenantName"],
        })
      }

      if (RegExp(`/${rules.name.pattern}/`).test(data.tenantName)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Tenant name must match ${rules.name.pattern}`,
          fatal: true,
          path: ["tenantName"],
        })
      }

    })
  }, [rules])

  const errors = useMemo(() => getFormErrors(CreateSubscriptionPayloadWithValidation, data), [CreateSubscriptionPayloadWithValidation, data])
  const [editable, setEditable] = useState(true)

  const findError = useCallback((name: keyof CreateSubscriptionPayload) => !!data?.[name] && !!findErrorByName(errors, name), [data, errors])
  const findErrorMessage = useCallback((name: keyof CreateSubscriptionPayload) => getErrorMessage(findErrorByName(errors, name)), [errors])

  const handleReservationChange = useCallback(async () => {
    try {
      if (!!reservations && reservations?.length > 0) {
        setReservations(undefined)
        await deleteTenantReservations()
      }
    } finally {
      setReservationError(undefined)
    }
  }, [deleteTenantReservations, reservations, setReservations])

  const handleChange = useCallback(async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target

    handleReservationChange()

    const url = value
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/[\s-]+/g, "-")

    if (name === "tenantDisplayName") {
      setData(() => ({
        tenantDisplayName: value,
        tenantName: url.replace(/^-+|-+$/g, ""),
        tenantReservationId: "",
        ...subscriptionData!,
      }))
    } else {
      setData((prevData) => ({
        tenantDisplayName: prevData?.tenantDisplayName ?? url,
        tenantReservationId: "",
        tenantName: url,
        ...subscriptionData!,
      }))
    }
  }, [handleReservationChange, subscriptionData])

  const handleCheckTenant = useCallback(async () => {
    const parsed = CreateSubscriptionPayloadWithValidation.safeParse(data)

    if (!parsed.success) return

    try {
      setLoading(true)
      const { reservationDate, tenantDisplayName, tenantName, reservationId } = await createTenantReservation(parsed.data)
      setData((prevData) => ({
        ...CreateSubscriptionPayloadWithValidation.parse(prevData),
        "tenantReservationId": reservationId!,
      }))

      setReservations([{
        name: tenantName!,
        displayName: tenantDisplayName,
        provisioningDate: reservationDate!,
        provisioningRequested: false,
        reservationId: reservationId!,
        state: "reservation",
        ownerUserId: user!.id!,
      }])
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setReservationError(error)
        setEditable(false)
      }
    } finally {
      setLoading(false)
    }
  }, [CreateSubscriptionPayloadWithValidation, createTenantReservation, data, setReservations, user])

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    const parsed = CreateSubscriptionPayloadWithValidation.safeParse(data)

    if (!parsed.success) return

    event.preventDefault()

    try {
      setLoading(true)
      await onSubmit?.(parsed.data)
    } catch (error) {
      setAlert({
        severity: "error",
      })
    } finally {
      setLoading(false)
    }
  }, [data, onSubmit, setAlert, CreateSubscriptionPayloadWithValidation])

  const CardWrapper = useCallback(({ children }: PropsWithChildren) => {
    if (!withCardWrapper) {
      return <>{children}</>
    }

    return (
      <Card
        variant="elevation"
        sx={{
          minHeight: 610,
          minWidth: "100%",
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardContent>
          {children}
        </CardContent>
        <CardActions />
      </Card>
    )
  }, [withCardWrapper])

  const SubmitButton = useCallback(() => {
    if (!!data && !!data.tenantReservationId) {
      return (
        <Button type="submit" disabled={!!errors?.length || loading}>
          Get Started
        </Button>
      )
    }

    return (
      <CustomButton onClick={handleCheckTenant} disabled={!!errors && errors.length > 0 || !!reservationError || !data} loading={loading}>
        Check tenant availability
      </CustomButton>
    )
  }, [data, errors, handleCheckTenant, loading, reservationError])

  const TenantWarning = useCallback(() => {
    if (!reservationError || reservationError.response?.status !== 409) {
      return null
    }

    return (
      <Alert icon={false} severity={"error"}>
        This tenant name is already taken, try a different one
      </Alert>
    )
  }, [reservationError])

  const TenantAvailableIcon = useCallback(() => {
    if (!!reservationError) {
      return (
        <IconButton color="error">
          <ErrorIcon />
        </IconButton>
      )
    }

    if (!!data && !!data.tenantReservationId) {
      return (
        <IconButton color="success">
          <CheckCircleIcon />
        </IconButton>
      )
    }

    return (
      <>
        <IconButton onClick={() => setEditable(rv => !rv)}>
          <CreateIcon />
        </IconButton>
      </>
    )
  }, [data, reservationError])

  if (success || (tenantsInProgress && tenantsInProgress.length)) {
    return (
      <CardWrapper>
        <CreateTenantSuccess email={user?.email} tenantId={data?.tenantName ?? tenantsInProgress?.[0].name} />
      </CardWrapper>
    )
  }

  if (!customer) {
    return null
  }

  return (
    <CardWrapper>
      <Typography variant="h5" mb={3}>
        {label}
      </Typography>
      <TenantWarning />
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          name={"tenantDisplayName"}
          label={"Tenant Name"}
          value={data?.tenantDisplayName}
          onChange={handleChange}
          error={findError("tenantDisplayName")}
          helperText={findErrorMessage("tenantDisplayName")}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name={"tenantName"}
          label={"Tenant URL"}
          value={data?.tenantName}
          defaultValue={`tenant.${domain}`}
          disabled={editable}
          onChange={handleChange}
          error={findError("tenantName")}
          helperText={findErrorMessage("tenantName")}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <>
                <Typography variant="body1" color={"gray"}>
                  .{domain}
                </Typography>
                <TenantAvailableIcon />
              </>
            ),
          }}
        />
        <List sx={{ listStyleType: "disc", mt: 1, ml: 3, p: 0 }} >
          <ListItem sx={{ display: "list-item", p: 0 }} >
            <Typography variant="body1">
              Tenants are distinct workspaces in Contextual
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }} >
            <Typography variant="body1">
              Create as many as you need
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }} >
            <Typography variant="body1">
              Each tenant has its own subscription - free or paid
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }} >
            <Typography variant="body1">
              Names often reference your business, products, and development processes, e.g. “MyProduct - Dev”, “MyProduct - QA”
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }} >
            <Typography variant="body1">
              The name you choose will be used to suggest a value for the Tenant URL above, or you can edit it
            </Typography>
          </ListItem>
        </List>
        <SubmitButton />
      </Box>
      <CardActions />
    </CardWrapper>
  )
}
