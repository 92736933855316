import { Tenant } from "@contextualio/contextual-silo-auth"
import React, { PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useTenantsService } from "../providers"
import { handleRedirect } from "../utils/navigation"
import { isValidUser } from "../utils/users"
import { useUser } from "./user"

const getPendingTenants = (arr1: Tenant[], arr2: Tenant[]): Tenant[] => {
  const namesInArray2 = new Set(arr2.map(item => item.name))
  return arr1.filter(({ name, state }) => !namesInArray2.has(name) && state !== "reservation")
}

type TenantsContext = {
  loading: boolean
  tenants?: Tenant[]
  tenantsInProgress?: Tenant[]
  fetchTenants: () => Promise<void>
  fetchTenantsWithState: () => Promise<void>
}

export const TenantsContext = React.createContext<TenantsContext | null>(null)
export const useTenants = () => useContext(TenantsContext)!

type Props = PropsWithChildren & {
  fallback: ReactElement
}

export const TenantsProvider = ({ children, fallback }: Props) => {
  const navigate = useNavigate()
  const { user } = useUser()
  const { listTenants, listTenantsWithState } = useTenantsService()

  const [tenantsInFlight, setTenantsInFlight] = useState(true)
  const [pendingTenantsInFlight, setPendingTenantsInFlight] = useState(true)

  const [tenants, setTenants] = useState<Tenant[]>()
  const [tenantsInProgress, setTenantsInProgress] = useState<Tenant[]>()

  const disableRedirect = useMemo(() =>
    window.location.pathname === "/business-signup" ||
    window.location.pathname === "/my-first-tenant" ||
    window.location.pathname === "/new-tenant" ||
    window.location.pathname === "/tenants"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [window.location.pathname])

  const fetchTenants = useCallback(async () => {
    const { items } = await listTenants()
    setTenants(items)
  }, [listTenants])

  const fetchTenantsWithState = useCallback(async () => {
    const { items } = await listTenantsWithState()

    const pendingTenants = getPendingTenants(items, tenants ?? [])

    setTenantsInProgress(pendingTenants)

    /*
      In case user leaves and returns to the auth app and has
      a in-progress tenant they are landed on the "We are on It!" screen
    */
    /*
      In case the users leaves without clicking go to my tenant,
      this should redirect them to the "We are on It!" screen
    */
    if (pendingTenants?.length === 1) {
      return navigate("/new-tenant")
    }

    if (tenants && tenants?.length > 0 && !disableRedirect) {
      if (tenants.length === 1) {
        return handleRedirect(tenants[0])
      }

      return navigate("/tenants")
    }
  }, [disableRedirect, listTenantsWithState, navigate, tenants])

  useEffect(() => {
    if (!tenants && !!user && isValidUser(user)) {
      try {
        fetchTenants()
      } catch (error) {
        console.error(error)
      } finally {
        setTenantsInFlight(false)
      }
    } else {
      setTenantsInFlight(false)
    }
  }, [fetchTenants, fetchTenantsWithState, tenants, user])

  useEffect(() => {
    if (!tenantsInFlight && !!tenants && !tenantsInProgress && !!user && isValidUser(user)) {
      try {
        fetchTenantsWithState()
      } catch (error) {
        console.error(error)
      } finally {
        setPendingTenantsInFlight(false)
      }
    } else {
      setPendingTenantsInFlight(false)
    }
  }, [fetchTenants, fetchTenantsWithState, tenants, tenantsInFlight, tenantsInProgress, user])


  if (tenantsInFlight || pendingTenantsInFlight) {
    return <>{fallback}</>
  }

  return (
    <TenantsContext.Provider value={{ loading: tenantsInFlight || pendingTenantsInFlight, tenants, fetchTenants, tenantsInProgress, fetchTenantsWithState }}>
      {children}
    </TenantsContext.Provider>
  )
}
