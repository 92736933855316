import { User } from "@auth0/auth0-react"
import { PropsWithChildren, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { useAuth0 } from "../../context/auth0"
import { DeepPartial } from "../../types/pages"
import { isValidUser } from "../../utils/users"

type Props = PropsWithChildren & {
  user?: DeepPartial<User>
}

export const InvitationGuard = ({ children, user }: Props) => {
  const navigate = useNavigate()
  const { returnUrl } = useAuth0()
  const disableSignupGuard = useMemo(() =>
    window.location.pathname === "/new-tenant" ||
    window.location.pathname === "/business-signup" ||
    window.location.pathname === "/tenants"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [window.location.pathname])

  useEffect(() => {
    if (disableSignupGuard) return

    if (user) {
      if (isValidUser(user)) {
        return window.location.replace(returnUrl!)
      }

      if (!!user.termsAccepted) {
        return navigate("/verify")
      }
    }

    return navigate("/invitations")
  }, [disableSignupGuard, navigate, returnUrl, user])

  return <>{children}</>
}
