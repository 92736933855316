import { Tenant } from "@contextualio/contextual-silo-auth"

export const handleRedirect = (tenant: Tenant) => {
  if (window.location.hostname !== "localhost") {
    return window.location.replace(`${window.location.protocol}//${tenant.baseDomain}`)
  }

  const split = tenant.baseDomain.includes("/") ? tenant.baseDomain.split("/") : undefined
  const route = split?.at(-1)

  return window.location.replace(`http://localhost:3000/${route ? route : ""}`)
}
