import { ListResponse, RecordResponse } from "@contextualio/contextual-responses"
import { CreateTenantReservationPayload, Tenant, TenantReservation, TenantRules } from "@contextualio/contextual-silo-auth"
import { CreateTenantPayload, CreateTenantReservationResponse } from "@contextualio/contextual-silo-auth"
import { AxiosInstance } from "axios"

export const TenantsService = (axiosInstance: AxiosInstance) => {
  const listTenants = async (signal?: AbortSignal): Promise<ListResponse<Tenant>> => {
    const response = await axiosInstance.request({
      method: "get",
      url: "/tenants",
      signal,
    })

    return response.data
  }

  const listTenantsWithState = async (signal?: AbortSignal): Promise<ListResponse<Tenant>> => {
    const response = await axiosInstance.request({
      method: "get",
      url: "/tenants-state",
      signal,
    })

    return response.data
  }

  const listTenantsByAccount = async (accountId: string, signal?: AbortSignal): Promise<ListResponse<Tenant>> => {
    const response = await axiosInstance.request({
      method: "get",
      url: `/tenants/${accountId}/account`,
      signal,
    })

    return response.data
  }

  const createTenant = async (data: CreateTenantPayload, signal?: AbortSignal): Promise<RecordResponse<Tenant>> => {
    const response = await axiosInstance.request({
      method: "post",
      url: "/tenants",
      signal,
      data,
    })

    return response.data
  }

  const getTenant = async (tenantId: string, signal?: AbortSignal): Promise<Tenant> => {
    const response = await axiosInstance.request({
      method: "get",
      url: `/tenants/${tenantId}`,
      signal,
    })

    return response.data
  }

  const getTenantRules = async (signal?: AbortSignal): Promise<TenantRules> => {
    const response = await axiosInstance.request({
      method: "get",
      url: "/tenants-schema",
      signal,
    })

    return response.data
  }

  const getTenantReservations = async (signal?: AbortSignal): Promise<TenantReservation[]> => {
    const response = await axiosInstance.request({
      method: "get",
      url: "/tenant-reservations",
      signal,
    })

    return response.data
  }

  const createTenantReservation = async (data: CreateTenantReservationPayload, signal?: AbortSignal): Promise<CreateTenantReservationResponse> => {
    const response = await axiosInstance.request({
      method: "post",
      url: "/tenant-reservation",
      signal,
      data,
    })

    return response.data
  }

  const deleteTenantReservations = async (signal?: AbortSignal): Promise<void> => {
    const response = await axiosInstance.request({
      method: "delete",
      url: "/tenant-reservations",
      signal,
    })

    return response.data
  }

  return {
    listTenants,
    listTenantsWithState,
    listTenantsByAccount,
    getTenant,
    getTenantRules,

    createTenant,
    getTenantReservations,
    createTenantReservation,
    deleteTenantReservations,
  }
}
