import DarkModeIcon from "@mui/icons-material/DarkMode"
import ListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import LightModeIcon from "@mui/icons-material/LightMode"
import LogoutIcon from "@mui/icons-material/Logout"
import { Avatar, Badge, Box, Divider, Menu, MenuItem, styled, Typography } from "@mui/material"
import { SyntheticEvent, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useAuth0 } from "../../context/auth0"
import { useTenants } from "../../context/tenant"
import { useSettings } from "../../context/theme"

const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  left: "40%",
}))

type Props = {
  disabled?: boolean
}

export const DropDown = ({ disabled = false }: Props) => {
  const navigate = useNavigate()
  const { user, logout } = useAuth0()
  const { mode, setMode } = useSettings()

  const { tenants } = useTenants()

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleDropdownOpen = useCallback((event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleDropdownClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const TenantListMenuItem = useCallback(() => {
    if (!tenants || !tenants.length || window.location.pathname === "/tenants") {
      return null
    }

    return (
      <MenuItem sx={{ p: 1.5 }} onClick={() => navigate("/tenants")}>
        <ListBulletedIcon fontSize="small" sx={{ marginRight: 2, fontSize: "1.375rem", color: "text.secondary" }} />
        Tenants
      </MenuItem>
    )
  }, [navigate, tenants])

  if (disabled) {
    return null
  }

  return (
    <>
      <Badge
        overlap="circular"
        onClick={handleDropdownOpen}
        sx={{
          cursor: "pointer",
          position: "absolute",
          top: "14px",
          right: "14px",
          borderRadius: "10%",
        }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Avatar
          alt={"Avatar"}
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src={user?.picture}
        />
      </Badge>
      <Menu
        sx={{ "& .MuiMenu-paper": { width: 230, marginTop: 4 } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ pt: 2, pb: 2, px: 4, pl: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Avatar
                alt={"Avatar"}
                src={user?.picture || "/images/avatars/1.png"}
                sx={{ width: "2.5rem", height: "2.5rem" }}
              />
            </Badge>
            <Box sx={{ display: "flex", marginLeft: 3, alignItems: "flex-start", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: 300 }}>{user?.nickname}</Typography>
              <Typography variant="body2" sx={{ fontSize: "0.8rem", color: "text.disabled" }}>
                Admin
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <MenuItem sx={{ p: 1.5 }} onClick={() => setMode(mode === "dark" ? "light" : "dark")}>
          {mode === "light" && <>
            <DarkModeIcon fontSize="small" sx={{ marginRight: 2, fontSize: "1.375rem", color: "text.secondary" }} />
            Dark Mode
          </>}
          {mode === "dark" && <>
            <LightModeIcon fontSize="small" sx={{ marginRight: 2, fontSize: "1.375rem", color: "text.secondary" }} />
            Light Mode
          </>}
        </MenuItem>
        <TenantListMenuItem />
        <MenuItem sx={{ p: 1.5 }} onClick={() => logout({ returnTo: window.location.origin })}>
          <LogoutIcon fontSize="small" sx={{ marginRight: 2, fontSize: "1.375rem", color: "text.secondary" }} />
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
